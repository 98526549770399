import styled, { createGlobalStyle, css } from 'styled-components';

const Root = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  `}
`;

const Main = styled.main`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.large} 0;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  `}
`;

const Presentation = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: ${theme.colors.white};

    ${theme.breakpoints.down.sm} {
      display: none;
    }
  `}
`;

const Banner = styled.figure`
  width: 400px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContentLogo = styled.div`
  ${() => css`
    margin-bottom: 30px;
  `}
`;

const Global = createGlobalStyle`
  html, body, #__next {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
  }
`;

export { Root, Global, Main, Presentation, Banner, ContentLogo };
