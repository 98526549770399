export enum WhitelabelAcoyId {
  whitelabelAcoy = '45e0c297-a78e-4868-8bda-a664c9dd5805',
}

export type WhitelabelThemeModel = {
  id: string;
  companyImgUrl: string;
  companyImgUrlMin: string;
  theme: Record<string, any>;
  messages: {
    accountInvitation: Record<string, any>;
    confirmEmail: Record<string, any>;
    forgotPassword: Record<string, any>;
    forgotPasswordSuccessful: Record<string, any>;
    login: Record<string, any>;
    resetPassword: Record<string, any>;
    resetPasswordSuccessful: Record<string, any>;
    signup: Record<string, any>;
    signupSuccessful: Record<string, any>;
  };
  whitelabelId: string;
  createdAt: string;
  updateAt?: string;
};
