import styled, { css } from 'styled-components';
import { Button } from '@/common/components/button';
import { Card } from '@/common/components/card';
import { FieldInput } from '@/common/components/form/field-input';

const Root = styled.div`
  padding: 0 8px;
`;

const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.company};
    margin-bottom: 10px;
    text-align: center;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.xxbold};
    letter-spacing: 0.3em;
    text-transform: uppercase;
  `}
`;

const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    margin: 0 auto 30px;
    text-align: center;
    font-weight: 500;
  `}
`;

const CardWrapper = styled(Card)`
  ${({ theme }) => css`
    max-width: 420px;
    width: 100%;
    padding: ${theme.spacings.medium} ${theme.spacings.small};
  `}
`;

const FieldInputWrapper = styled(FieldInput)``;

const ButtonWrapper = styled(Button)`
  ${() => css`
    margin: 10px auto 10px;
    padding: 10px 30px;
    gap: 10px;
  `}
`;

const ContentAction = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

    > p {
      margin-right: 4px;
    }
    > a {
      color: ${theme.colors.primary};
      text-decoration: none;
    }
  `}
`;

const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.danger};
    text-align: center;
  `}
`;

export {
  Root,
  Title,
  SubTitle,
  ButtonWrapper,
  CardWrapper,
  ContentAction,
  ErrorMessage,
  FieldInputWrapper,
};
