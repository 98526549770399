import { getPublicApiInstanceV4 } from '@/common/config/axios-instance';
import {
  PendingEmailConfirmationError,
  UnexpectedError,
} from '@/common/errors';
import { handleAxiosError } from '@/common/helpers/axios-error';
import { ResendConfirmEmailCase } from '@/modules/auth/types/resend-confirm-email-case';

export const httpResendConfirmationEmail = async ({
  email,
}: ResendConfirmEmailCase.Params): Promise<ResendConfirmEmailCase.Result> => {
  try {
    await getPublicApiInstanceV4().post('/auth/signup/resend-confirm-email', {
      email,
    });
  } catch (err) {
    const { status } = handleAxiosError(err);
    switch (status) {
      case 406:
        throw new PendingEmailConfirmationError();
      default:
        throw new UnexpectedError();
    }
  }
};
