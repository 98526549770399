import { Button } from '@/common/components/button';
import { CardContent } from '@/common/components/card';
import { Heading } from '@/common/components/heading';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from '@/common/components/modal';
import { GlobalContext } from '@/common/contexts/global-context';
import { WhitelabelAcoyId } from '@/common/models/whitelabel-theme.model';
import { httpResendConfirmationEmail } from '@/modules/auth/api/resend-confirm-email/http-resend-confirm-email';
import { FormikProvider, useFormik } from 'formik';
import { LoginCase } from '@/modules/auth/types/login-case';
import { LoginSchema } from '@/modules/auth/templates/login-template/login.schema';
import {
  ButtonWrapper,
  CardWrapper,
  ContentAction,
  ErrorMessage,
  Root,
  SubTitle,
  Title,
} from '@/modules/auth/templates/login-template/styles';
import Link from 'next/link';
import { AuthContext } from '@/common/contexts/auth-context';
import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AwaitingEmailConfirmationErrorName } from '@/common/consts/errors';
import {
  PAGE_AUTH_FORGOT_PASSWORD,
  PAGE_AUTH_SIGNUP,
} from '@/common/consts/pages';
import toast from 'react-hot-toast';
import { FiSend } from 'react-icons/fi';
import { FieldInputPassword } from '@/common/components/form/field-input-password';
import { FieldInput } from '@/common/components/form/field-input';

const LoginTemplate = () => {
  const authContext = useContext(AuthContext);
  const { whitelabelTheme } = useContext(GlobalContext);
  const [isOpenResendConfirmationEmail, setIsOpenResendConfirmationEmail] =
    useState(false);
  const { mutateAsync: onLogin, error } = useMutation(authContext.login, {
    onError: (err: Error) => {
      if (err.name === AwaitingEmailConfirmationErrorName) {
        setIsOpenResendConfirmationEmail(true);
      }
    },
  });
  const { mutateAsync: resendConfirmationEmail } = useMutation(
    httpResendConfirmationEmail,
    {
      onSuccess: () => {
        toast.success('Email reenviado com sucesso');
      },
      onError: () => {
        toast.error('Falha ao realizar a solicitação');
      },
    }
  );

  const formik = useFormik<Omit<LoginCase.Params, 'domain'>>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async params => {
      await onLogin({
        ...params,
        domain: window.location.hostname,
      });
    },
  });

  const handleResendConfirmationEmail = async () => {
    await resendConfirmationEmail({ email: formik.values.email });
  };

  const handleClose = () => {
    setIsOpenResendConfirmationEmail(!isOpenResendConfirmationEmail);
  };

  return (
    <Root>
      <Modal isOpen={isOpenResendConfirmationEmail}>
        <ModalTitle onClose={handleClose}>
          <Heading>Email não confirmado</Heading>
        </ModalTitle>
        <ModalContent>Deseja reenviar convite para validação?</ModalContent>
        <ModalFooter>
          <Button onClick={handleClose} outline>
            Fechar
          </Button>
          <Button type="submit" onClick={handleResendConfirmationEmail}>
            Enviar <FiSend size={20} />
          </Button>
        </ModalFooter>
      </Modal>
      <CardWrapper>
        <CardContent>
          <Title>Bem vindo de volta</Title>
          <SubTitle>
            Entre na plataforma preenchendo com seus dados de acesso
          </SubTitle>
          <FormikProvider value={formik}>
            <FieldInput
              label={'Email'}
              name={'email'}
              placeholder={'email@exemplo.com'}
            />
            <FieldInputPassword
              label={'Senha'}
              name={'password'}
              placeholder={'********'}
              enableSubmit
            />
            <ErrorMessage>{error && error.message}</ErrorMessage>
            <ButtonWrapper
              mode="company"
              loading={formik.isSubmitting}
              onClick={formik.submitForm}>
              Entrar
            </ButtonWrapper>
          </FormikProvider>
        </CardContent>
      </CardWrapper>
      {whitelabelTheme.whitelabelId === WhitelabelAcoyId.whitelabelAcoy && (
        <ContentAction>
          <p>Não tem cadastro? </p>
          <Link href={PAGE_AUTH_SIGNUP}>Vem com a gente!</Link>
        </ContentAction>
      )}
      <ContentAction>
        <Link href={PAGE_AUTH_FORGOT_PASSWORD}>Ops, esqueci minha senha</Link>
      </ContentAction>
    </Root>
  );
};

export { LoginTemplate };
