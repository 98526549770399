import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import Image from 'next/image';
import {
  Root,
  Global,
  Main,
  Presentation,
  Banner,
  ContentLogo,
} from '@/common/templates/auth-template/styles';
import { GlobalContext } from '@/common/contexts/global-context';

export type PageProps = {
  children: React.ReactNode;
  className?: string;
};

const AuthTemplate = ({ children, className }: PageProps) => {
  const { whitelabelTheme } = useContext(GlobalContext);
  const router = useRouter();

  const getBanner = () => {
    switch (router.pathname) {
      case '/auth/forgot-password':
        return (
          <Image
            src="/img/password-reset.svg"
            width={103 * 2}
            height={123 * 2}
            layout="fixed"
            alt={''}
          />
        );
      case '/auth/forgot-password-successful':
        return (
          <Image
            src="/img/password-reset.svg"
            width={103 * 2}
            height={123 * 2}
            layout="fixed"
            alt={''}
          />
        );
      case '/auth/reset-password-successful':
        return (
          <Image
            src="/img/password-success.svg"
            width={250}
            height={250}
            layout="fixed"
            alt={''}
          />
        );
      default:
        return (
          <Image
            src={whitelabelTheme.theme.loginImg}
            width={774}
            height={860}
            layout="fill"
            alt={''}
          />
        );
    }
  };

  return (
    <>
      <Global />
      <Root className={className}>
        <Main>
          <ContentLogo>
            <Image
              src={whitelabelTheme.companyImgUrl}
              width={whitelabelTheme.theme.logoSize.default.width}
              height={whitelabelTheme.theme.logoSize.default.height}
              alt={''}
            />
          </ContentLogo>
          {children}
        </Main>
        <Presentation>
          <Banner>{getBanner()}</Banner>
        </Presentation>
      </Root>
    </>
  );
};

export { AuthTemplate };
